// Copyright (C) 2017-2023 Smart code 203358507

@import (reference) '~@stremio/stremio-colors/less/stremio-colors.less';
@import (reference) '~stremio/common/screen-sizes.less';

.intro-container {
    display: flex;
    flex-direction: row;
    justify-content: center;
    width: 100%;
    height: 100%;
    background:
        linear-gradient(@color-background-dark3-80, @color-background-dark3-80),
        url('/images/intro_background.jpg');
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    background-origin: border-box;
    overflow-y: auto;

    .form-container {
        flex: none;
        width: 28rem;
        margin: auto;
        padding: 2rem 0;

        .logo-container {
            display: flex;
            flex-direction: row;
            align-items: center;
            justify-content: center;
            margin-bottom: 3rem;

            .logo {
                flex: none;
                width: 4rem;
                height: 4rem;
                margin-right: 1rem;
                opacity: 0.9;
            }

            .name {
                flex: none;
                width: 8rem;
                height: 4rem;
                fill: @color-surface-dark4-90;
            }
        }

        .form-button {
            display: flex;
            flex-direction: row;
            align-items: center;
            justify-content: center;
            padding: 0.5rem 1rem;

            .icon {
                flex: none;
                width: 1rem;
                height: 2rem;
                margin-right: 1rem;
                fill: @color-surface-light5-90;
            }

            .label {
                flex-grow: 0;
                flex-shrink: 1;
                flex-basis: auto;
                font-size: 1.1rem;
                font-weight: 500;
                color: @color-surface-light5-90;
                text-align: center;
            }
        }

        .facebook-button {
            min-height: 4.5rem;
            margin: 1rem 0;
            background: var(--color-facebook);

            .label {
                font-size: 1.2rem;
            }
        }

        .login-form-button {
            display: block;
            margin: 1rem 0;
            text-align: center;
            color: @color-surface-dark2-90;

            &:hover {
                .login-label {
                    text-decoration: underline;
                }
            }

            &:focus {
                outline: none;
                background-color: @color-surface-light5-20;
            }

            .login-label {
                font-weight: 500;
                color: @color-accent4-light1-90;
            }
        }

        .credentials-text-input {
            display: block;
            width: 100%;
            margin: 1rem 0;
            padding: 1rem;
            border-bottom: thin solid @color-surface-90;
            color: @color-surface-light5;

            &:hover {
                background-color: @color-surface-light5-20;
            }

            &:focus {
                border-bottom-color: @color-secondaryvariant2-light1-90;

                &::placeholder {
                    color: @color-secondaryvariant2-light1-90;
                }
            }

            &::placeholder {
                color: @color-surface-dark2-90;
            }
        }

        .forgot-password-link-container {
            display: flex;
            flex-direction: row;
            justify-content: flex-end;
            margin: 1rem 0;
            text-align: right;

            .forgot-password-link {
                flex-grow: 0;
                flex-shrink: 1;
                flex-basis: auto;
                padding: 0.5rem 1rem;
                color: @color-surface-light3-90;

                &:hover {
                    text-decoration: underline;
                    color: @color-secondaryvariant2-light1-90;
                }

                &:focus {
                    outline: none;
                    background-color: @color-surface-light5-20;
                }
            }
        }

        .error-message {
            margin: 1rem 0;
            padding: 0 1rem;
            text-align: center;
            color: @color-accent5-90;
        }

        .submit-button {
            min-height: 4rem;
            margin: 1rem 0;
            background-color: @color-accent3;

            &:hover {
                background-color: @color-accent3-light1;
            }

            .label {
                font-size: 1.2rem;
            }
        }

        .guest-login-button, .signup-form-button {
            margin-top: 1rem;
            padding: 1rem;

            &:hover {
                .label {
                    text-decoration: underline;
                }
            }

            &:focus {
                outline: none;
                background-color: @color-surface-light5-20;
            }
        }
    }
}

.loading-modal-container {
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: @color-background-dark5-40;

    .loader-container {
        flex: none;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        padding: 2rem;
        background-color: @color-surface-light5;

        @keyframes flash {
            0% {
                opacity: 0.4;
            }

            100% {
                opacity: 1.0;
            }
        }

        .icon {
            flex: none;
            width: 5rem;
            height: 5rem;
            margin-bottom: 1rem;
            fill: @color-background-dark5-90;
            animation: 1s linear infinite alternate flash;
        }

        .label {
            font-size: 2rem;
            color: @color-background-dark5-90;
            animation: 1s linear infinite alternate flash;
        }
    }
}

@media only screen and (max-width: @minimum) {
    .intro-container {
        .form-container {
            flex: 0 1 auto;
            width: 100%;
            padding: 2rem 1.5rem;
        }
    }
}