// Copyright (C) 2017-2023 Smart code 203358507

@import (reference) '~stremio/common/screen-sizes.less';

.meta-row-placeholder-container {
    .header-container {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: flex-end;
        padding: 0 1rem;
        margin-bottom: 1rem;

        .title-container {
            flex: 1;
            max-height: 2.4em;
            font-size: 1.8rem;
            color: var(--color-placeholder-text);

            &:empty {
                height: 1.2em;
                background: linear-gradient(to right, var(--color-placeholder-background) 0 40%, transparent 40% 100%);
            }
        }

        .see-all-container {
            flex: none;
            display: flex;
            flex-direction: row;
            align-items: center;
            max-width: 12rem;
            padding: 0.2rem;

            &:focus {
                outline: none;
                background-color: var(--color-placeholder-background);
            }

            .label {
                flex: 0 1 auto;
                max-height: 1.2em;
                font-size: 1.3rem;
                font-weight: 500;
                text-transform: uppercase;
                color: var(--color-placeholder-text);
            }

            .icon {
                flex: none;
                height: 1.3rem;
                margin-left: 0.5rem;
                fill: var(--color-placeholder-text);
            }
        }
    }

    .meta-items-container {
        display: flex;
        flex-direction: row;
        align-items: stretch;

        .meta-item {
            flex: 1;
            margin: 1rem;

            &:not(:first-child) {
                margin-left: 1.5rem;
            }

            .poster-container {
                padding-bottom: calc(100% * var(--poster-shape-ratio));
                background-color: var(--color-placeholder-background);
            }

            .title-bar-container {
                display: flex;
                flex-direction: row;
                align-items: center;
                height: 2.8rem;

                .title-label {
                    flex: none;
                    width: 60%;
                    height: 1.2rem;
                    background-color: var(--color-placeholder-background);
                }
            }
        }
    }
}

@media only screen and (max-width: @minimum) {
    .meta-row-placeholder-container {
        .meta-items-container {
            .meta-item {
                margin: 0.5rem;

                &:not(:first-child) {
                    margin-left: 0.5rem;
                }

                .title-bar-container {
                    margin-top: 0.5rem;
                }
            }
        }
    }
}