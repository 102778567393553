// Copyright (C) 2017-2023 Smart code 203358507

@import (reference) '~@stremio/stremio-colors/less/stremio-colors.less';
@import (reference) '~stremio/common/screen-sizes.less';

.meta-row-container {
    overflow: visible;

    .header-container {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: flex-end;
        padding: 0 1rem;
        margin-bottom: 1rem;

        .title-container {
            flex: 1;
            max-height: 2.4em;
            font-size: 1.8rem;
            color: @color-secondaryvariant2-light1-90;
        }

        .see-all-container {
            flex: none;
            display: flex;
            flex-direction: row;
            align-items: center;
            max-width: 12rem;
            padding: 0.2rem;

            &:focus {
                outline: none;
                background-color: @color-background-light3;
            }

            &:hover {
                .label {
                    color: @color-secondaryvariant2-light2-90;
                }

                .icon {
                    fill: @color-secondaryvariant2-light2-90;
                }
            }

            .label {
                flex: 0 1 auto;
                max-height: 1.2em;
                font-size: 1.3rem;
                font-weight: 500;
                text-transform: uppercase;
                color: @color-secondaryvariant2-light1-90;
            }

            .icon {
                flex: none;
                height: 1.3rem;
                margin-left: 0.5rem;
                fill: @color-secondaryvariant2-light1-90;
            }
        }
    }

    .message-container {
        max-height: 3.6em;
        padding: 0 1rem;
        font-size: 1.3rem;
        color: @color-secondaryvariant2-light1-90;
    }

    .meta-items-container {
        display: flex;
        flex-direction: row;
        align-items: stretch;
        overflow: visible;

        .meta-item {
            &:not(:first-child) {
                margin-left: 0.5rem;
            }

            &.poster-shape-poster {
                flex: calc(1 / var(--poster-shape-ratio));
            }

            &.poster-shape-square {
                flex: 1;
            }

            &.poster-shape-landscape {
                flex: calc(1 / var(--landscape-shape-ratio));
            }
        }
    }
}

@media only screen and (max-width: @minimum) {
    .meta-row-container {
        .header-container {
            padding: 0 0.5rem;

            .title-container {
                margin-right: 0.5rem;
                white-space: nowrap;
                text-overflow: ellipsis;
            }
        }

        .meta-items-container {
            .meta-item {
                &:not(:first-child) {
                    margin-left: 0;
                }
            }
        }
    }
}