// Copyright (C) 2017-2023 Smart code 203358507

@import (reference) '~@stremio/stremio-colors/less/stremio-colors.less';

.subtitles-menu-container {
    height: 23rem;
    display: flex;
    flex-direction: row;

    .languages-container, .variants-container, .subtitles-settings-container {
        flex: none;
        align-self: stretch;
        display: flex;
        flex-direction: column;

        .languages-header, .variants-header, .settings-header {
            flex: none;
            align-self: stretch;
            max-height: 2.4em;
            margin: 1rem;
            font-weight: 600;
            color: @color-surface-light5-90;
        }

        .languages-list, .variants-list {
            flex: 1;
            align-self: stretch;
            overflow-y: auto;

            .language-option, .variant-option {
                display: flex;
                flex-direction: row;
                align-items: center;
                padding: 1rem 1.5rem;

                &:global(.selected), &:hover {
                    background-color: @color-background;
                }

                .language-label, .variant-label {
                    flex: 1;
                    max-height: 2.4em;
                    font-size: 1.1rem;
                    color: @color-surface-light5-90;
                }

                .icon {
                    flex: none;
                    width: 0.5rem;
                    height: 0.5rem;
                    border-radius: 100%;
                    margin-left: 1rem;
                    background-color: @color-accent3-90;
                }
            }
        }
    }

    .languages-container {
        width: 12rem;
    }

    .variants-container {
        width: 12rem;

        .no-variants-container {
            flex: 1;
            align-self: stretch;
            padding: 1rem;

            .no-variants-label {
                max-height: 4.8em;
                font-weight: 500;
                color: @color-surface-90;
            }
        }
    }

    .subtitles-settings-container {
        width: 16rem;

        .spacing {
            flex: 1;
        }

        .discrete-input {
            margin: 0 1.5rem 1rem;
        }

        .advanced-button {
            flex: none;
            align-self: flex-end;
            max-width: calc(100% - 3rem);
            margin: 0 1.5rem 1rem;
            white-space: nowrap;
            text-overflow: ellipsis;
            color: @color-surface-light5-90;

            &:hover {
                text-decoration: underline;
            }

            &:global(.disabled) {
                color: @color-surface-90;
            }
        }
    }
}